<template>
  <Portlet
    title="Video Director"
    icon="video"
    class="videoDirector"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading || refreshing}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getData()"
      />

      <router-link
        v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
        :to="configUrl"
        title="View"
        class="btn btn-sm btn-secondary"
      >
        <font-awesome-icon
          class="gray mr-2"
          icon="wrench"
        />
        <span>{{ $t('configuration') }}</span>
      </router-link>
      <button
        class="btn btn-sm btn-secondary" 
        @click="openConfirmModal()"
      >
        <font-awesome-icon
          class="gray mr-2"
          icon="redo-alt"
        /><span>Restart</span>
      </button>
    </template>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="camerasAreAvailable">
        <table class="defaultTable">
          <colgroup>
            <col>
            <col>
          </colgroup>
          <thead>
            <tr>
              <th class="font-weight-bold">
                VideoRecorder Status
              </th>
              <th class="font-weight-bold d-none d-lg-table-cell">
                Camera Location
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in cameras"
              :key="'tr' + index"
            >
              <td
                v-if="item.status"
                class="d-none d-lg-table-cell"
              >
                <span               
                  v-tooltip="`${ getVideoRecorderStatusTooltip(item.status) }`"
                  data-toggle="tooltip"
                  data-placement="top"
                  class="d-xl-table-cell"
                > 
                  <CameraOperationStatusText
                    :status="item.status"
                  /> 
                </span>
              </td>
              <td
                v-if="item.location"
              >
                <div class="btn-group btn-group-sm">
                  <CameraLocationText
                    :location="item.location"
                    :status="item.cameraOperatorStatus"
                    :tooltip="item.clientId"
                  />
                </div>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-secondary btn-block"
                  @click="enableSidebar(item)"
                >
                  <font-awesome-icon
                    class="gray mr-1"
                    icon="info-circle"
                  />
                  <span>
                    {{ $t('details') }}
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="recording"
          class="row mt-2"
        >
          <template v-for="(image, index) in images">
            <div
              v-if="image != null && image.base64Data && image.base64Data != ''"
              :key="`videoDirector-imageDiv-${ index }`"
              class="col-12 col-sm-6"
            >
              <img
                :src="`data:image/png;base64,${ image.base64Data }`"
                :alt="image.fileName"
                class="w-100"
              >
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>

      <Sidebar
        v-if="cameras && currentId"
        :show-sidebar="showSidebar"
        :sidebar-width="600"
        @close="disableSidebar"
      >
        <CameraManagementDetail
          :installation-id="installationId"
          :camera-id="currentId"
          :recorder-id="currentRecorderId"
          :is-video-director="true"
          :lane-number="laneNumber"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'VideoDirector'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartVideoDirectorAndCameraOperators()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'
import { SweetModal } from 'sweet-modal-vue';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "VideoDirector",
  components: {
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    CameraOperationStatusText: () => import('@/components/VideoDirector/CameraOperationStatusText.vue'),
    CameraLocationText: () => import('@/components/VideoDirector/CameraLocationText.vue'),
    CameraManagementDetail: () => import('@/components/CameraManagement/CameraManagementDetail.vue'),
    SweetModal
  },
  mixins: [
    authenticationMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      recording: false,
      cameras: [
        {
          id: 0,
          lastActive: '',
          status: '',
          cameraOperatorStatus: '',
        }
      ],
      images: null,
      dataInterval: null,
      imageInterval: null,
      cancelSource: null,
      imageCancelSource: null,
      refreshing: false,
      showSidebar: false,
      currentId: null,
      currentRecorderId: null
    };
  },
  computed: {
    camerasAreAvailable () {
      if (this.cameras == null) {
        return false;
      }
      if (this.cameras.length == null) {
        return false;
      }
      if (this.cameras.length === 0) {
        return false;
      }
      return true;
    },
    configUrl: function () {
      if (!this.laneNumber) //its a Dualslalom installation with own routing
      {
        return { path: `/installation/${ this.installationId }/tracker/config/videodirector` };
      }
      else
      {
        return { path: `/installation/${ this.installationId }/tracker/config/videodirector/lane/${ this.laneNumber }` };
      }
    }
  },
  created () {
    this.getData();
    this.createDataInterval();
  },
  mounted () {
    this.$eventBus.$on('videoDirector_showImages', this.startedRecording);
  },
  beforeDestroy () {
    this.$eventBus.$off('videoDirector_showImages', this.startedRecording)
    this.cancelDataInterval();
    this.cancelImageInterval();
    this.cancelImageRequest();
    this.cancelRequest();
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    enableSidebar (item) {
      this.currentRecorderId = item.id;
      this.currentId = item.cameraId;
      this.showSidebar = true;
    },
    disableSidebar () {
      this.currentId = null;
      this.currentRecorderId = null;
      this.showSidebar = false;
    },
    getVideoRecorderStatusTooltip (status) {
      switch(status) {
        case('unknown'):
          return 'VideoRecorder Status is unknown';
        case('idle'):
          return 'Camera is not in operation';
        case('recording'):
          return 'Camera is recording';
        case('failure'):
          return 'Camera has an error';
        default:
          return 'No further information available';
      }
    },
    pathToDetails (itemId) {
      if (!this.laneNumber)
      {
        return `/installation/${ this.installationId }/tracker/videodirector/${ itemId }`;
      }
      else
      {
        return `/installation/${ this.installationId }/tracker/videodirector/lane/${ this.laneNumber }/recorder/${ itemId }`;
      }
    },
    startedRecording () {
      this.cancelRequest();
      this.createImageInterval();
      this.recording = true;
    },
    createImageInterval () {
      if (this.recording == true) {
        return;
      }
      this.imageInterval = window.setInterval (() => {
        this.getImages();
      }, 500 );
    },
    cancelImageInterval () {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
      this.recording = false;
    },
    getImages () {
      this.cancelImageRequest(); //if i comment out this then it gets not cancelled
      this.imageCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.imageCancelSource.token };
      if (this.cameras == null) {
        return;
      }
      let request = `/VideoDirector/GetBothImages?installationId=${ this.installationId }`;
      this.cameras.forEach((camera) => {
        request += `&ids=${ camera.id }`;
      });
      if (this.laneNumber)
      {
        request += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(request, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data) {
            this.images = response.data;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelImageInterval();
        });
    },
    cancelImageRequest () {
      if (this.imageCancelSource) {
        this.imageCancelSource.cancel();
      }
    },
    createDataInterval () {
      this.dataInterval = window.setInterval(() => {
        this.getData();
      }, 1000);
    },
    cancelDataInterval () {
      window.clearInterval(this.dataInterval);
      this.dataInterval = null;
      this.loading = false;
    },
    getData () {
      this.refreshing = true;
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      let url = `VideoDirector/GetAll?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          
          this.cancelSource = null;
          this.cameras = response.data;
          this.cameras.forEach(camera => {
            if (camera == null) {
              return;
            }
            if (camera.status == null) {
              return;
            }
            if (camera.status.toLowerCase() == 'recording') {
              this.startedRecording();
            }

            if (camera.status.toLowerCase() != 'recording') {
              this.cancelImageInterval();
            }
          });
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    async restartVideoDirectorAndCameraOperators () {
      await this.restartVideoDirector();
      this.restartCameraOperators();
    },
    async restartVideoDirector () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=VideoDirector&laneNumber=${this.laneNumber}`)
        .then(async (response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartVideoDirector',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              await this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart VideoDirector");
                });
            }
        }
        });
    },
    restartCameraOperators () {
      this.axios.get(`/Endpoint/GetCameraOperators?installationId=${this.installationId}&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartCameraOperator',
              'Args': ''
            };
            if (response.data && response.data.length > 0)
            {
              response.data.forEach(device => {
                  this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ device.uuid }`, commandRequest)
                  .then(() => {})
                  .finally(() => {});              
              });
            }
        }
        });
    }
  }
}
</script>

<style>
.videoDirector .m-portlet__body {
  min-height: 118px;
}
</style>
